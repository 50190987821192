<template>
    <div>
      <h1>Perfil de Usuario</h1>
      <div v-if="loading">Cargando usuario...</div>
      <div v-else-if="error">{{ error }}</div>
      <div v-else>
        <p>ID: {{ usuario.id }}</p>
        <p>Nombre de usuario: {{ usuario.userName }}</p>
        <p>Email: {{ usuario.email }}</p>
        <p>Roles: {{ usuario.roles.join(', ') }}</p>
        <p>Estado: {{ usuario.isActive ? 'Activo' : 'Inactivo' }}</p>
        <p>Fecha de creación: {{ usuario.createdAt }}</p>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from "vue";
  import authService from "../../composables/api/authService";
  
  export default {
    name: "perfil",
    setup() {
      const usuario = ref(null); // Cambia a null para un solo usuario
      const loading = ref(true);
      const error = ref(null);
  
      const fetchUsuario = async () => {
        try {
          loading.value = true;
          const response = await authService.getCurrentUser (); // Asegúrate de que este método devuelva el usuario actual
          console.log('Respuesta de la API:', response);
          
          // Asigna la respuesta directamente al objeto usuario
          usuario.value = response;
        } catch (err) {
          error.value = "Error al cargar el usuario: " + err.message;
          console.error(err);
        } finally {
          loading.value = false;
        }
      };
  
      onMounted(() => {
        fetchUsuario();
      });
  
      return {
        usuario,
        loading,
        error,
      };
    },
  };
  </script>