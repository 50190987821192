<template>
  <div class="viewport">
    <div class="card w-max m-auto p-4">
      <div class="mb-3">
        <router-link :to="'perfil'" class="text-emerald-300 hover:text-emerald-600">&lt; Volver atrás</router-link>
      </div>
      <h1 class="font-bold">Usuarios</h1>
      <div v-if="errorMessage" class="text-red-500">{{ errorMessage }}</div>
      <form>
        <div class="form-group">
          <label for="email" class="text-black">Email</label>
          <input type="email"
            class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
            id="email" v-model="form.email" />
        </div>

        <div class="form-group">
          <label for="password" class="text-black">Password</label>
          <input type="password"
            class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-300 focus:ring focus:ring-blue-200"
            id="password" v-model="form.password" />
        </div>

        <button type="submit" class="mt-3 bg-emerald-300 hover:bg-emerald-500 text-white font-bold py-2 px-4 rounded"
          @click.prevent="submitForm">
          Guardar
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import userService from "../../composables/api/userService";

export default {
  setup() {
    const router = useRouter();
    const errorMessage = ref('');
    const form = ref({
      id: 0,
      email: '',
      password: '',
    });

    const agregarUsuario = async () => {
      const Usuario = {
        email: form.value.email,
        password: form.value.password
      };

      try {
        await userService.addUsuario(Usuario);
        router.push('/perfil');
      } catch (error) {
        errorMessage.value = 'Error al agregar el usuario. Inténtalo de nuevo.';
        console.error(error);
      }
    };

    const submitForm = async () => {
      if (!form.value.email || !form.value.password) {
        errorMessage.value = 'Por favor, completa todos los campos.';
        return;
      }

      if (form.value.id == 0 || !form.value.id) {
        await agregarUsuario();
      } else {
        await editarUsuario();
      }

      // Reset the form
      form.value = {
        id: 0,
        email: '',
        password: '',
      };
    };

    return {
      form,
      submitForm,
      errorMessage,
    };
  },
};
</script>